$(document).ready(function () {
	$('.reviews__slider').slick({
	  infinite: true,
	  slidesToShow: 2,
	  slidesToScroll: 2,
	  dots: true,
	  responsive: [
	  	{
	  		breakpoint: 1024,
	  		settings: {
	  			slidesToScroll: 1,
	  			slidesToShow: 1
	  		}
	  	}
	  ]
	});
})