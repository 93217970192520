function modalCall(mod, btn){
    var modal = $(mod);
    var modWrp = modal.find('.bs-rqmodal__wrp');
    var btn = $(btn);

    $(".success").show();

    btn.on("click", function(){
        $('.bs-rqmodal').hide();
        modal.show();
        // if ($(window).width() > 992){
        //     var hgt = modal.find(".bs-rqmodal__inner").outerHeight();
        //     modal.find('.bs-rqmodal__content').outerHeight(hgt);
        // }
    });
    modWrp.on("click", function(){
        modal.hide();
        window.location.replace("http://www.babycare.kz");
    });
    $('.js-bs-rqmodal__close').click(function () {
        modal.hide();
    });
}
modalCall('.js-cbo-call__modal', ".js-cbo-call__btn");
modalCall('.js-cbo-call__modal2', ".js-cbo-call__btn2");

// var app = new Vue({
//   el: '#cbo-app',
//   data: {
//     message: 'Hello Vue!'
//   }
// })