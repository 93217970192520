$(document).ready(function (){
    var reviews = $('.reviews'),
        gotop = $('.gotop'),
        controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '.reviews',
            triggerHook: 0.8
        })
        .setClassToggle('.gotop', 'gotop--v')
        .addTo(controller);
});